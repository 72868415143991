<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="border-0 p-3 d-flex justify-content-center map-container" style="min-height: 60px;">
			<olMap :canvasFeatures="canvasFeatures" @mapReady="getMapContext" @layerAdded="cacheVectorLayer" @mapClick="handleMapClick" />
			<div ref="markerOverlay" class="marker-popup" :style="{ display: overlayVisibility }">
				<div class="popover-arrow"></div>
				<div class="close" @click="closePopup">
					<i class="flaticon-circle"></i>
				</div>
				<div class="marker-popup-title" :style="{ background: `linear-gradient(200deg, ${hovered.colors[0]}, ${hovered.colors[1]})` }">
					<h5>
						<router-link :to="{ name: 'site', params: { id: hovered.siteId } }">
							{{ hovered.name }}
						</router-link>
					</h5>
				</div>
				<div class="marker-popup-body">
					<div>
						<span class="info-title">Status: </span>
						<h6>{{ hovered.status }}</h6>
					</div>
					<div>
						<span class="info-title"> Info: </span>
						<div>{{ hovered.status_data }} at {{ hovered.status_change }}</div>
					</div>
					<div>
						<span class="info-title">GPS: </span>
						<h6>{{ hovered.lat }}, {{ hovered.lon }}</h6>
					</div>
				</div>
			</div>
		</div>
		<div>
			<div class="d-flex justify-content-between align-items-center px-3 pb-2">
				<div>Show Out of Network Remotes</div>
				<div class="scroll-note">Click map to zoom with scroll</div>
			</div>
		</div>
	</div>
</template>

<script>
import { fromLonLat } from 'ol/proj';
import { asArray } from 'ol/color';
import { Icon, Style, Fill, Stroke, Text, Circle } from 'ol/style';
import Overlay from 'ol/Overlay';

let clusterInner = num =>
	new Style({
		image: new Circle({
			radius: 10,
			fill: new Fill({
				color: '#166fc9',
			}),
		}),
		text: new Text({
			text: num,
			fill: new Fill({
				color: '#fff',
			}),
		}),
	});

let clusterOuter = new Style({
	image: new Circle({
		radius: 15,
		fill: new Fill({
			color: [83, 153, 252, 0.5],
		}),
	}),
});

const setCanvasMarkers = remotes => {
	let features = remotes.map(remote => {
		let lon = remote.gps.lon > 180 ? remote.gps.lon - 360 : remote.gps.lon;
		if (remote.gps) {
			return {
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: fromLonLat([lon.toFixed(4), remote.gps.lat.toFixed(4)]),
				},
				properties: {
					name: remote.NetModemName,
					did: remote.DID,
					lat: remote.gps.lat,
					lon: remote.gps.lon,
					siteId: remote.SiteId,
					status: remote.status,
					status_change: remote.status_change,
					status_data: remote.status_data,
					type: 'marker',
				},
			};
		}
	});
	return {
		name: 'inNetwork',
		geojson: {
			type: 'FeatureCollection',
			features: features,
		},
		cluster: true,
		style: feature => {
			let srcs = {
				OK: '/media/markers/marker_green.png',
				ALARM: '/media/markers/marker.png',
				SCPC: '/media/markers/marker_blue.png',
				DEACTIVATED: '/media/markers/marker_white.png',
				BEAMCHANGE: '/media/svg/Custom/beamswitch.svg',
				default: '/media/markers/marker_grey.png',
			};
			let features = feature.get('features');
			// if (features.length > 1) {
			// 	return [clusterInner(features.length.toString()), clusterOuter];
			// } else {
			let status = features[0].get('status');
			return new Style({
				image: new Icon({
					anchor: [0.5, 1],
					anchorXUnits: 'fraction',
					anchorYUnits: 'fraction',
					src: srcs?.[status] || srcs['default'],
				}),
			});
			// }
		},
	};
};

const setFootprint = path => {
	let over = false;
	let under = false;
	path = path.map(coord => {
		if (coord.longitude > 170) over = true;
		if (coord.longitude < -170) under = true;
		return fromLonLat([coord.longitude, coord.latitude]);
	});
	if (over && under) {
		path = path.map(coord => {
			if (coord.longitude > 0) coord.longitude -= 360;
			return fromLonLat([coord.longitude, coord.latitude]);
		});
	}
	return {
		geojson: {
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'Polygon',
						coordinates: [path],
					},
					properties: {
						type: 'footprint',
					},
				},
			],
		},
		style: new Style({
			stroke: new Stroke({
				color: asArray('blue'),
				width: 2,
			}),
			fill: new Fill({
				color: [0, 0, 0, 0],
			}),
		}),
	};
};

const overlayColors = {
	OK: ['#c4fce5', '#37c583'],
	ALARM: ['#f7ccd0', '#ED6B75'],
	DEACTIVATED: ['#e2eeff', '#bac3d1'],
	SCPC: ['#bdd9fc', '#659BE0'],
	default: ['#e2eeff', '#bac3d1'],
};

export default {
	name: 'NetworkMap',
	components: {
		olMap: () => import('@/view/content/lib/ol.vue'),
	},
	props: ['inNetwork', 'outOfNetwork', 'solarOutage', 'serverId', 'networkId'],
	data() {
		return {
			loaded: false,
			canvasFeatures: null,
			footprint: null,
			hovered: {
				siteId: 0,
				colors: ['#e2eeff', '#bac3d1'],
			},
			map: null,
			markerOverlay: null,
			overlayVisibility: 'none',
			vLayers: {},
		};
	},
	methods: {
		getMapContext(map) {
			this.map = map;
			this.markerOverlay = new Overlay({
				element: this.$refs.markerOverlay,
				stopEvent: false,
				positioning: 'center-left',
				offset: [12, 0],
			});
			this.overlayVisibility = '';
			this.map.addOverlay(this.markerOverlay);
			// this.map.on('pointermove', event => this.handlePointermove(event));
		},
		cacheVectorLayer(layer) {
			this.vLayers = { ...layer, ...this.vLayers };
		},
		handleMapClick(feature) {
			if (feature.get('type') !== 'footprint') {
				let group = feature.get('features');
				if (group.length > 1) {
					console.log(group);
				} else {
					this.hovered = group[0].getProperties();
					this.hovered.colors = overlayColors[this.hovered.status] || overlayColors.default;
					this.markerOverlay.setPosition(group[0].getGeometry().getCoordinates());
				}
			}
			// console.log(feature.get('type'));
		},
		handlePointermove(event) {
			this.markerOverlay.setPosition(undefined);
			this.map.forEachFeatureAtPixel(event.pixel, feature => {
				if (feature.get('type') !== 'footprint') {
					let features = feature.get('features');
					if (features.length > 1) {
						console.log(features);
					} else {
						this.hovered = features[0].getProperties();
						this.hovered.colors = overlayColors[this.hovered.status] || overlayColors.default;
						this.markerOverlay.setPosition(features[0].getGeometry().getCoordinates());
					}
				}
			});
		},
		closePopup() {
			this.markerOverlay.setPosition(undefined);
		},
	},
	created() {
		this.loaded = false;
		this.$http.get(`idirect/network/${this.serverId}/${this.networkId}/footprint`).then(resp => {
			this.footprint = resp.data.data[0];
			this.canvasFeatures = [setFootprint(this.footprint.path), setCanvasMarkers(this.inNetwork)];
			this.loaded = true;
		});
	},
};
</script>

<style scoped lang="scss">
// .map-container {
// 	position: relative;
// 	min-height: 200px;
// 	transition: all 300ms ease-in-out;
// }
.marker-popup {
	background: white;
	width: 275px;
	border-radius: 5px;
	box-shadow: 2px 4px 13px -5px rgb(99, 123, 124);
}
.popover-arrow {
	left: 0;
	position: absolute;
	top: 50%;
	background-color: #fff;
	transform: translate(-50%, -50%) rotate(45deg);
	height: 14px;
	width: 14px;
}
.marker-popup-title {
	padding: 10px 20px 10px 10px;
	border-radius: 5px 5px 0 0;
}
.marker-popup-title a {
	color: rgb(44, 44, 44);
}
.marker-popup-title a:hover {
	color: $primary-hover;
}
.marker-popup-title h5 {
	margin: 0;
}
.marker-popup-body {
	min-height: 80px;
	padding: 10px;
}
.marker-stat {
	font-weight: 700;
}
.info-title {
	font-size: 1em;
	color: rgb(92, 91, 91);
}
.scroll-note {
	color: rgb(155, 154, 154);
}
.close {
	position: absolute;
	right: 5px;
	top: 2px;
}
.close i {
	color: rgb(15, 15, 15);
	cursor: pointer;
}
</style>
